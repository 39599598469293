<template>
  <div>

    <v-row class="row">
      <v-col cols="2">
        <ValidationProvider slim rules="required" v-slot="{ errors }">
        <FileItemType
          v-model="item.type"
          @input="onTypeSwitch"
          :error-messages="errors"
          label="Тип"
        />
      </ValidationProvider>
      </v-col>
      <v-col cols="3">
        <ValidationProvider slim name="startDate" rules="required" v-slot="{ errors }">
          <VTextFieldOutlined
            ref="firstInput"
            type="date"
            v-model="item.startDate"
            placeholder="ДД.ММ.ГГГГ"
            :error-messages="errors"
            label="От"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="3">
        <ValidationProvider slim name="endDate" rules="required" v-slot="{ errors }">
          <VTextFieldOutlined
            v-model="item.endDate"
            type="date"
            placeholder="ДД.ММ.ГГГГ"
            label="До"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>

      <v-col v-if="$scopedSlots.actions">
        <slot name="actions"></slot>
      </v-col>
    </v-row>

    <Content
      :type="item.type"
      :file.sync="item.file"
      :link.sync="item.link"
    />
  </div>
</template>

<script>
  import isEqual from 'lodash/isEqual'
  import { VTextFieldOutlined } from '@/shared/ui'
  import FileItemType from './FileItemType.vue'
  import Content from './Content.vue'
  import { FILE_ITEM_TYPE_FILE, FILE_ITEM_TYPE_LINK } from '@/consts/document'

  export default {
    components: {
      VTextFieldOutlined,
      Content,
      FileItemType
    },
    props: {
      type: {
        type: String
      },
      file: {
        type: Object
      },
      link: {
        type: String
      },
      endDate: {
        type: String
      },
      startDate: {
        type: String
      },
      uuid: {
        type: String,
      }
    },
    data () {
      return {
        item: { ...this.$props }
      }
    },
    watch: {
      $props (value) {
        const newValue = { ...value }
        if (!isEqual(newValue, this.item)) {
          this.item = newValue
        }
      },
      item: {
        deep: true,
        handler (value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      onTypeSwitch (type) {
        if (type === FILE_ITEM_TYPE_FILE) {
          this.item.link = null
        } else if (type === FILE_ITEM_TYPE_LINK) {
          this.item.file = null
        }
      }
    }
  }
</script>
